const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://kingo.dvgeo.app' : 'http://192.168.100.106:3027',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://kingo.dvgeo.app' : 'http://192.168.100.106:3027',
    api: 'api/',
    apiSocket: 'kingo::1.2',
    nameDir: 'kingo',
    package: 'com.kingo.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyCQbtktcOj-Cq6dU9iide_brkWQ5IYp4cI',
    appName: 'KinGo',
    provider: '',
    colorPrimary: '#E74763',
    colorDark: '#262B60',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'com.kingo.passenger',
    playStoreDriverId: 'com.kingo.driver',
    appStorePassengerId: '6657993121',
    appStoreDriverId: '6657993201',
    email: "administracion1@goappcol.com",
};
export default config;
